<template>
  <div>
    <h3>{{ $t('customers') }}</h3>
  </div>
</template>

<script>
  export default {
    name: 'Settings',

    data: () => ({

    }),
  }
</script>
